import React, { Component } from "react"
import {Link} from 'react-router-dom'
import logo from "./black_swan_opt.png"
import "./App.css"

class Home extends Component {
  render() {
    return (
      <div className="App">
        <Link to="/about">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
      </div>
    )
  }
}

export default Home
