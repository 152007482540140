import React, { Component } from "react"
import "./App.css"
import linkedIn from './linkedin.svg'
import github from './github.svg'

class About extends Component {

  render() {
    return (
      <div className="App">
        <p>Hi there!</p>
        <p>I'm Adam, an IP litigator turned software developer.</p>
        <p className='about-logos'>
        <a href='https://www.linkedin.com/in/adam-reis/' 
        target="_blank" 
        rel="noopener noreferrer">
          <img src={linkedIn} className="about-logo" alt="linkedin" />
        </a>
        <a href='https://github.com/resjudicoda' 
        target="_blank"
        rel="noopener noreferrer">
          <img src={github} className="about-logo" alt="github" />
        </a>
        </p>
      </div>
    );
  }
}

export default About
